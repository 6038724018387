import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as campaignIdRoutes } from './campaignId/routes';
import { routes as workOrderRoutes } from './workOrder/routes';

const AllCampaign = lazyWithRetry(() => import('./all/page'));
const Index = lazyWithRetry(() => import('./page'));
const NewCampaign = lazyWithRetry(() => import('./new/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'all',
    element: <Suspense><AllCampaign /></Suspense>,
  },
  {
    path: 'workOrder',
    children: workOrderRoutes,
  },
  {
    path: 'new',
    element: <Suspense><NewCampaign /></Suspense>,
  },
  {
    path: ':campaignId',
    children: campaignIdRoutes,
  },
];
